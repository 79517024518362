.login-container {
  background-image: url('https://maki-static1.s3.amazonaws.com/zqv33nrm_image/zqv33nrm_0502851371001/imagen226.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.credentials-container {
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 50px;
  background-color: rgba(254, 253, 255, 0.616);
  width: 350px;
  height: 400px;
  padding: 20px;
}

.wellcome-container {
  margin-top: 50px;
  padding: 20px;
}

.logo-login {
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
}

.logo-login img {
  width: 150px;
  height: auto;
}

.logo-acl {
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
}

.logo-acl img {
  width: 300px;
  height: auto;
}

.logo-fenix {
  text-align: center;
}

.logo-fenix img {
  width: 150px;
  height: auto;
}

.credentials-info-group {
  margin-top: 0px;
  text-align: center;
  padding: 20px;
}

.wellcome-info-group {
  margin-top: 0px;
  text-align: center;
  padding: 20px;
}

.login__info {
  padding: 20px;
  text-align: center;
}

.wellcome-info-group p {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: white;
  margin-top: 0px;
}

.wellcome-info-group h2 {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  color: white;
  margin-bottom: 0px;
  margin-top: 0px;
}

.credentials-container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: bold;
}

.credentials-info-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.credentials-basic-info {
  display: flex;
  align-items: center;
}

.icono-login {
  background-color: #082a53;
  padding: 15px;
  color: white;
  border-bottom-left-radius: 30%;
  border-top-left-radius: 30%;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 15px;
}

.text-box-login {
  flex: 1;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #203f669c;
  padding: 14px;
  color: white;
}

.text-box-login:focus {
  outline: none;
}

.password-input-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ffffff;
}

.access_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #0d47a1;
  border-radius: 12px;
  padding: 10px 20px;
  color: #0d47a1;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
}

.access_button:hover {
  background-color: #0d47a1;
  color: #ffffff;
}

.link-button {
  margin-top: 20px;
  background: none;
  border: none;
  color: #122455;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
}

@media (max-width: 768px) {
  .login-container {
    background-image: url('https://maki-static1.s3.amazonaws.com/zqv33nrm_image/zqv33nrm_0502851371001/imagen352.jpeg');
  }

  .login__info p,
  .login__info h2 {
    font-family: 'Poppins', sans-serif;
    color: black;
  }

  .credentials-container {
    width: 100%;
    margin: 20px;
    padding: 15px;
  }

  .login-container {
    padding: 10px;
  }

  .logo-login img,
  .logo-acl img {
    max-width: 200px;
  }

  .credentials-info-group {
    padding: 10px;
  }

  .text-box-login {
    padding: 10px;
  }

  .icono-login {
    padding: 10px;
  }

  .access_button {
    font-size: 10px;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .credentials-container {
    width: 100%;
    height: auto;
    margin: 10px;
    padding: 10px;
  }

  .credentials-info-group {
    padding: 5px;
  }

  .text-box-login {
    padding: 8px;
  }

  .access_button {
    font-size: 10px;
    padding: 8px 10px;
  }

  .logo-login img,
  .logo-acl img {
    max-width: 150px;
  }

  .wellcome-container {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .basic-info-form-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .credentials-container {
    width: 100%;
    height: auto;
    margin: 20px;
    padding: 15px;
  }

  .logo-login img {
    width: 120px;
  }

  .logo-acl img {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .login__info {
    padding: 10px;
  }

  .login__info p {
    font-size: 13px;
  }

  .login__info h2 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .access_button {
    font-size: 10px;
    padding: 8px;
  }
}

@media (max-width: 768px) {

  .success-notification,
  .error-notification {
    font-size: 12px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .logo-acl img {
    content: url('../../../src/static/ACL-Color.png');

  }
}

@media (max-width: 480px) {
  .logo-acl img {
    content: url('../../../src/static/ACL-Color.png');
  
  }
}